<template>
  <div id="newService">
    <LoadingSpinner :showHide="showHide" />

    <PageHeader heading="Create New AGCDN Service" />
    <div class="mt-2">
      <b-row>
        <b-col cols="4">
          <b-alert show variant="warning">This tool is DEPRECATED. Create Service in <a href="https://github.com/pantheon-systems/tf-agcdn/actions/workflows/create-service.yml">pantheon-systems/tf-agcdn</a></b-alert>
        </b-col>
      </b-row>
    </div>

    <b-form class="mt-2" v-if="false">
      <b-row>
        <b-col cols="4">
          <h2>Basic Information</h2>
          <b-form-group>
            <label for="serviceNameInput">Service/Client Name (required):</label>
            <b-form-input v-model="serviceName" required :state="serviceName !== null && serviceName !== ''"></b-form-input>
            <b-form-text>
              The new service will be created
              <span v-if="serviceName">with the name <strong>Advanced CDN - {{ serviceName }}</strong></span>
              <span v-else> by prepending "Advanced CDN - " to this value. Start typing to preview the new name</span>
              .
            </b-form-text>
          </b-form-group>

          <h2>Documentation</h2>
          <b-form-group>
            <label for="orgUuidInput">Org UUID:</label>
            <b-form-input id="orgUuidInput" v-model="orgUuid"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label>Site UUID(s):</label>
            <b-form-group>
              <b-button variant="primary" v-b-modal.bulk-site-uuid>Paste a list of Site UUIDs</b-button>
              <b-modal
                id="bulk-site-uuid"
                title="Site UUID Import"
                no-close-on-backdrop
                @ok="parseBulkSiteUuid"
              >
                <b-form-group>
                  <label for="bulkUuid">Site UUIDs (comma-separated):</label>
                  <b-form-textarea id="bulkUuid" v-model="bulkSiteUuid" rows="3" max-rows="6"></b-form-textarea>
                </b-form-group>
              </b-modal>
              <p v-if="siteUuids.length > 0" class="mb-0"><em>You have entered {{ this.siteUuids.length }} UUID(s).</em></p>
            </b-form-group>

            <b-input-group v-for="(siteUuid, idx) in siteUuids" :key="idx" class="mb-2">
              <b-form-input v-model="siteUuids[idx]"></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" v-on:click="addNewSiteUuid">+</b-button>
                <b-button variant="danger" v-on:click="removeSiteUuid(idx)">-</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <CollapsibleFormGroup collapseName="addl-docs" title="Additional Documentation Details">
            <b-form-group>
              <label for="sfOppLink">SalesForce Opportunity Link:</label>
              <b-form-input v-model="sfOppLink" id="sfOppLink"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="acdnSku">AGCDN SKU:</label>
              <b-form-select id="acdnSku" v-model="acdnSku" :options="availableAcdnSkus"></b-form-select>
            </b-form-group>
            <b-form-group>
              <label for="bookingDate">Booking Date:</label>
              <b-form-datepicker id="bookingDate" v-model="bookingDate"></b-form-datepicker>
            </b-form-group>
            <b-form-group>
              <label for="scopingDoc">Scoping Doc Link:</label>
              <b-form-input id="scopingDoc" v-model="scopingDoc"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="jiraLink">Jira Link:</label>
              <b-form-input id="jiraLink" v-model="jiraLink"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="serviceDesc">Service Description:</label>
              <b-form-textarea id="serviceDesc" v-model="serviceDesc" rows="3" placeholder="Enter a summary of service requirements..."></b-form-textarea>
            </b-form-group>
          </CollapsibleFormGroup>

          <h2>Canonical Domains</h2>
          <p><em>Note that in most cases it's best to let the platform handle canonical domain redirects. Users should point their canonical domain at Fastly and their non-canonical domain at the Platform, then select a primary domain on their dashboard. In cases where they cannot use the dashboard's Primary Domain feature, set up canonical domain redirects here.</em></p>
          <b-form-group>
            <label for="canonicalOnOff">Use canonical domain redirects on the edge:</label>
            <b-form-checkbox id="canonicalOnOff" switch size="lg" v-model="useCanonical"></b-form-checkbox>
          </b-form-group>

          <b-form-group v-if="useCanonical">
            <label>Canonical Domains:</label>
            <b-row>
              <b-col cols="5"><strong>From</strong></b-col>
              <b-col cols="5"><strong>To</strong></b-col>
            </b-row>
            <b-row v-for="(couplet, idx) in canonicalDomains" :key="idx" class="mb-2">
              <b-col cols="5">
                <b-form-input placeholder="From" v-model="canonicalDomains[idx].from"></b-form-input>
              </b-col>
              <b-col cols="5">
                <b-form-input placeholder="To" v-model="canonicalDomains[idx].to"></b-form-input>
              </b-col>
              <b-col cols="2">
                <b-button-group>
                  <b-button variant="primary" v-on:click="addNewCanonical">+</b-button>
                  <b-button variant="danger" v-on:click="removeCanonical(idx)">-</b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-form-group>

          <CollapsibleFormGroup collapseName="known-domains" title="Additional Domains" subHeading="These domains will be added to the service and to the Firebase clients list.">
            <b-alert show variant="warning">Do not add the *.agcdn.pantheon.io domain for AGCDN-behind-GCDN setup</b-alert>
            <b-form-group>
              <b-button variant="primary" v-b-modal.bulk-domain>Paste a list of domains</b-button>
              <b-modal
                id="bulk-domain"
                title="Bulk Domain Import"
                no-close-on-backdrop
                @ok="parseBulkDomains"
              >
                <b-form-group>
                  <label for="bulkDomain">Domains (comma-separated):</label>
                  <b-form-textarea id="bulkDomain" v-model="bulkDomain" rows="3" max-rows="6"></b-form-textarea>
                </b-form-group>
              </b-modal>
            </b-form-group>

            <b-form-group>
              <label>Add more domains here:</label>
              <b-row v-for="(d, i) in additionalDomains" :key="i" class="mb-2">
                <b-col>
                  <b-form-input placeholder="Domain" v-model="additionalDomains[i]"></b-form-input>
                </b-col>
                <b-col>
                  <b-button-group>
                    <b-button variant="primary" v-on:click="addNewAddlDomain">+</b-button>
                    <b-button variant="danger" v-on:click="removeAddlDomain(i)">-</b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-form-group>
          </CollapsibleFormGroup>

          <CollapsibleFormGroup collapseName="optional-configs" title="Optional Configurations" subheading="Set up IP block/allowlist and WAF here">
            <div v-if="this.acdnSku == 'advanced_cdn_waf_io'" id="wafIoConfigOptions">
              <h3>WAF/IO Selection</h3>
              <p><em>Choose the WAF/IO features to bootstrap.</em></p>
              <b-form-group>
                <label for="wafIoConfig">WAF/IO Combinations:</label>
                <b-form-select id="wafIoConfig" v-model="wafIoConfig" :options="availableWafIoConfigTypes" required :state="wafIoConfig !== null && wafIoConfig !== ''"></b-form-select>
              </b-form-group>
            </div>

            <div id="ipBAConfigOptions">
              <h3>IP Blocklist/Allowlist</h3>
              <b-form-group>
                <label for="ipBAOnOff">Use IP-based blocklist/allowlist functionality?</label>
                <b-form-checkbox id="ipBAOnOff" switch size="lg" v-model="useIpBA"></b-form-checkbox>
              </b-form-group>
              <div v-if="useIpBA" id="selectIpBAType">
                <p><em>Select either a blocklist (specific IPs blocked) or allowlist (only specific IPs allowed) below.</em></p>
                <b-form-group>
                  <label for="ipBAType">Type:</label>
                  <b-form-select id="ipBAType" v-model="ipBAType" :options="availableIpBATypes"></b-form-select>
                </b-form-group>
              </div>
            </div>

            <div id="geoBAConfigOptions">
              <h3>Geo Blocklist/Allowlist</h3>
              <b-form-group>
                <label for="geoBAOnOff">Use Geo-based blocklist/allowlist functionality?</label>
                <b-form-checkbox id="geoBAOnOff" switch size="lg" v-model="useGeoBA"></b-form-checkbox>
              </b-form-group>
              <div v-if="useGeoBA" id="selectGeoBAType">
                <p><em>Select either a blocklist (specific countries/regions blocked) or allowlist (only specific countries/regions allowed) below.</em></p>
                <b-form-group>
                  <label for="geoBAType">Type:</label>
                  <b-form-select id="geoBAType" v-model="geoBAType" :options="availableGeoBATypes"></b-form-select>
                </b-form-group>
              </div>
            </div>
          </CollapsibleFormGroup>

        </b-col>

        <b-col cols="4">
          <h2>Create Service</h2>

          <b-form-group>
            <b-button variant="warning" @click="createService">Create Service</b-button>
          </b-form-group>

          <b-form-group v-if="showCreateResults">
            <hr>
            <h2>Service Creation Results</h2>
            <ul>
              <li v-if="createResultsDisplay.step1" v-html="createResultsDisplay.step1"></li>
              <li v-if="createResultsDisplay.step10" v-html="createResultsDisplay.step10"></li>
              <li v-if="createResultsDisplay.step2" v-html="createResultsDisplay.step2"></li>
              <li v-if="createResultsDisplay.step3a" v-html="createResultsDisplay.step3a"></li>
              <li v-if="createResultsDisplay.step3b" v-html="createResultsDisplay.step3b"></li>
              <li v-if="createResultsDisplay.step4a" v-html="createResultsDisplay.step4a"></li>
              <li v-if="createResultsDisplay.step4b" v-html="createResultsDisplay.step4b"></li>
              <li v-if="createResultsDisplay.step4c" v-html="createResultsDisplay.step4c"></li>
              <li v-if="createResultsDisplay.step5a" v-html="createResultsDisplay.step5a"></li>
              <li v-if="createResultsDisplay.step5b" v-html="createResultsDisplay.step5b"></li>
              <li v-if="createResultsDisplay.step8a" v-html="createResultsDisplay.step8a"></li>
              <li v-if="createResultsDisplay.step8b" v-html="createResultsDisplay.step8b"></li>
              <li v-if="createResultsDisplay.step8c" v-html="createResultsDisplay.step8c"></li>
              <li v-if="step8dAvailable" v-html="createResultsDisplay.step8d"></li>
              <li v-if="createResultsDisplay.step9a" v-html="createResultsDisplay.step9a"></li>
              <li v-if="createResultsDisplay.step9b" v-html="createResultsDisplay.step9b"></li>
              <li v-if="createResultsDisplay.step9c" v-html="createResultsDisplay.step9c"></li>
              <li v-if="createResultsDisplay.step9d" v-html="createResultsDisplay.step9d"></li>
              <li v-if="createResultsDisplay.step9e" v-html="createResultsDisplay.step9e"></li>
              <li v-if="createResultsDisplay.step6a" v-html="createResultsDisplay.step6a"></li>
              <li v-if="createResultsDisplay.step6b" v-html="createResultsDisplay.step6b"></li>
              <li v-if="createResultsDisplay.step11a" v-html="createResultsDisplay.step11a"></li>
              <li v-if="createResultsDisplay.step11b" v-html="createResultsDisplay.step11b"></li>
              <li v-if="createResultsDisplay.step7" v-html="createResultsDisplay.step7"></li>
            </ul>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

  </div>
</template>

<style lang="scss" scoped>
  ul {
    padding: 0;
    li {
      list-style-type: none;
    }
  }
  .normaltext {
    color: #333;
  }
</style>

<script>
import PageHeader from '@/components/PageHeader'
import FirebaseApi from '@/services/api/Firebase'
import ServicesApi from '@/services/api/Services'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import CollapsibleFormGroup from '@/components/CollapsibleFormGroup'

export default {
  name: 'create-new-service',
  components: {
    PageHeader,
    LoadingSpinner,
    CollapsibleFormGroup,
  },
  data() {
    return {
      serviceName: null,
      orgUuid: null,
      siteUuids: [null],
      bulkSiteUuid: null,
      useCanonical: false,
      canonicalDomains: [{from: null, to: null}],
      loading: false,
      showCreateResults: false,
      createResults: {},
      createResultsDisplay: {
        step1: '\u274C Create Fastly service',
        step10: '\u274C Add Pantheon origin',
        step2: '\u274C Add boilerplate VCL',
        step3a: '\u274C Add logging',
        step3b: `\u274C Add IO logging`,
        step4a: '\u274C Create canonical dictionary',
        step4b: '\u274C Create entries in canonical dictionary',
        step4c: '\u274C Add VCL snippets for canonical redirects',
        step5a: '\u274C Create pantheon_backends dictionary',
        step5b: '\u274C Add standard snippets',
        step8a: '\u274C Add WAF ACL',
        step8b: '\u274C Populate WAF ACL',
        step8c: '\u274C Set up WAF allowlist snippet',
        step8d: '\u274C Add WAF domain',
        step9a: '\u274C Add IP block/allowlist',
        step9b: '\u274C Add IP block/allowlist snippets',
        step9c: '\u274C Add Geo block/allowlist',
        step9d: '\u274C Add Geo block/allowlist snippets',
        step9e: '\u274C Set up blocked traffic logging snippets',
        step6a: '\u274C Add initial domains',
        step6b: '\u274C Set conditions for initial domains',
        step11a: '\u274C Activate service',
        step11b: '\u274c Begin provisioning TLS certificates',
        step7: '\u274C Add service to Firebase',
      },
      sfOppLink: '',
      acdnSku: '',
      availableAcdnSkus: [
        { value: 'advanced_cdn', text: 'Advanced CDN' },
        { value: 'advanced_cdn_waf_io', text: 'Advanced CDN + WAF/IO' },
      ],
      bookingDate: '',
      scopingDoc: '',
      jiraLink: '',
      serviceDesc: '',
      additionalDomains: [''],
      bulkDomain: null,
      wafIoConfig: '',
      availableWafIoConfigTypes: [
        { value: 'waf', text: 'WAF only' },
        { value: 'io', text: 'IO only' },
        { value: 'waf-io', text: 'Both WAF and IO' },
      ],
      useIpBA: true,
      ipBAType: 'blocklist',
      availableIpBATypes: [
        { value: 'blocklist', text: 'Blocklist' },
        { value: 'allowlist', text: 'Allowlist' },
      ],
      useGeoBA: true,
      geoBAType: 'blocklist',
      availableGeoBATypes: [
        { value: 'blocklist', text: 'Blocklist' },
        { value: 'allowlist', text: 'Allowlist' },
      ],
      tlsCsvDownloadUrl: '',
    }
  },
  methods: {
    addNewSiteUuid() {
      this.siteUuids.push(null)
    },
    removeSiteUuid(idx) {
      if (this.siteUuids.length == 1) {
        this.siteUuids = [null]
      } else {
        this.siteUuids.splice(idx, 1)
      }
    },
    parseBulkSiteUuid() {
      this.siteUuids = this.bulkSiteUuid.split(/[, ]+/)
    },
    addNewCanonical() {
      this.canonicalDomains.push([{from: null, to: null}])
    },
    removeCanonical(idx) {
      if (this.canonicalDomains.length == 1) {
        this.canonicalDomains = [{from: null, to: null}]
      } else {
        this.canonicalDomains.splice(idx, 1)
      }
    },
    addNewAddlDomain() {
      this.additionalDomains.push('')
    },
    removeAddlDomain(idx) {
      if (this.additionalDomains.length == 1) {
        this.additionalDomains = []
      } else {
        this.additionalDomains.splice(idx, 1)
      }
    },
    parseBulkDomains() {
      this.additionalDomains = this.bulkDomain.split(/[, ]+/)
    },
    async createService() {
      // Step 0: create the payload to send to the v2 API
      const payload = {
        name: `Advanced CDN - ${this.serviceName}`,
        canonical: {
          install: this.useCanonical,
          items: this.canonicalDomains
        },
        documentation: {
          client_name: this.serviceName,
          org_uuid: this.orgUuid,
          site_uuids: this.siteUuids,
          addl_data: {
            sf_opp_link: this.sfOppLink,
            acdn_sku: this.acdnSku,
            booking_date: this.bookingDate,
            scoping_doc_link: this.scopingDoc,
            jira_link: this.jiraLink,
            service_desc: this.serviceDesc,
          },
        },
        additionalDomains: this.additionalDomains,
        useWaf: this.useWaf,
        useIo: this.useIo,
      }

      // Go back through the additional documentation information and unset any blank entries.
      if (! this.sfOppLink) delete payload.documentation.addl_data.sf_opp_link;
      if (! this.acdnSku) delete payload.documentation.addl_data.acdn_sku;
      if (! this.bookingDate) delete payload.documentation.addl_data.booking_date;
      if (! this.scopingDoc) delete payload.documentation.addl_data.scoping_doc_link;
      if (! this.jiraLink) delete payload.documentation.addl_data.jira_link;
      if (! this.serviceDesc) delete payload.documentation.addl_data.service_desc;

      // Step 1: use the v2 API to create a new fastly service
      this.loading = true;
      let createdServiceId = '';

      try {
        // step 1: create the service
        const step1resp = await ServicesApi.addService({name: payload.name})
        this.showCreateResults = true;
        this.createResults.step1 = step1resp
        if (! step1resp.success) {
          throw "Unable to create the service!"
        }
        createdServiceId = step1resp.data.serviceId
        this.createResultsDisplay.step1 = `\u2705 Created Fastly service called "${step1resp.data.serviceName}" with id ${createdServiceId}. <a href="https://manage.fastly.com/configure/services/${createdServiceId}">Click here</a> to manage this service.`
        await ServicesApi.addBlackholeCondition({ serviceId: createdServiceId});
        // step 10: add pantheon origin
        const step10resp = await Promise.all([
          ServicesApi.addOrigin({ serviceId: createdServiceId, pop: 'chi' }),
          ServicesApi.addOrigin({ serviceId: createdServiceId, pop: 'yyz' }),
          ServicesApi.addOrigin({ serviceId: createdServiceId, pop: 'amsterdam' }),
          ServicesApi.addOrigin({ serviceId: createdServiceId, pop: 'sydney' })
        ])
        this.createResults.step10 = step10resp
        .every(r => !r.error)
        if (step10resp.every(r => !r.error)) {
          this.createResultsDisplay.step10 = '\u2705 Added Pantheon origin.'
        } else {
          // leave as-is
        }

        // step 2: add the boilerplate vcl
        const step2resp = await ServicesApi.addBoilerplate({serviceId: createdServiceId, useWaf: this.useWaf, useIo: this.useIo})
        this.createResults.step2 = step2resp
        if (step2resp.success) {
          this.createResultsDisplay.step2 = `\u2705 Added boilerplate VCL.`
        } else {
          // leave this as-is for now
        }

        // step 3a: set up logging
        const step3aresp = await ServicesApi.addLogging({
          serviceId: createdServiceId,
        })
        this.createResults.step3a = step3aresp
        if (step3aresp.success) {
          this.createResultsDisplay.step3a = `\u2705 Added BQ logging to dataset/table "client_logs.client_logs".`
        } else {
          // leave this as-is for now
        }

        // step 3b: set up IO logging
        if (this.useIo) {
          const step3bresp = await ServicesApi.setIoLogging({
            serviceId: createdServiceId,
          })
          this.createResults.step3b = step3bresp
          if (step3bresp.success) {
            this.createResultsDisplay.step3b = `\u2705 Added IO logging.`
          } else {
            // leave this as is for now
          }
        } else {
          this.createResultsDisplay.step3b = '\u2705 Skipped adding IO logging.'
        }

        // step 4 (optional): create canonical dictionary
        if (payload.canonical.install === true) {
          const step4aResp = await ServicesApi.createCanonicalDict({serviceId: createdServiceId})
          this.createResults.step4a = step4aResp
          if (step4aResp.success) {
            const dictId = step4aResp.data.id
            this.createResultsDisplay.step4a = `\u2705 Created canonical dictionary.`

            // step 4b: add canonical entries if specified
            if (payload.canonical.items.length > 0) {
              const step4bResp = await ServicesApi.createCanonicalEntries({
                serviceId: createdServiceId,
                dictId,
                entries: payload.canonical.items
              })
              this.createResults.step4b = step4bResp
              if (step4bResp.success) {
                this.createResultsDisplay.step4b = `\u2705 Added requested canonical domain entries to dictionary.`
              } else {
                // leave this as-is for now
              }
            } else {
              this.createResults.step4b = 'adding canonical entries skipped by customer request'
              this.createResultsDisplay.step4b = `\u2705 Skipped adding canonical domain entries; no domains specifed.`
            }

            // step 4c: create snippets to use that dictionary
            const step4cResp = await ServicesApi.createCanonicalSnippets({serviceId: createdServiceId})
            this.createResults.step4c = step4cResp
            if (step4cResp.success) {
              this.createResultsDisplay.step4c = `\u2705 Added vcl_recv and vcl_error snippets for canonical redirects.`
            } else {
              // leave this as-is for now
            }
          } else {
            // this is a bigger deal - tried and failed to create the dictionary
          }
        } else {
          this.createResults.step4a = 'canonical dictionary steps skipped by customer request'
          this.createResults.step4b = 'adding canonical entries skipped by workflow; no canonical dictionary'
          this.createResults.step4c = 'adding canonical snippets skipped by workflow; no canonical dictionary'
          this.createResultsDisplay.step4a = `\u2705 Skipped adding canonical domain dictionary; no canoncal redirects requested.`
          this.createResultsDisplay.step4b = `\u2705 Skipped adding canonical domain entries; no canonical redirects requested.`
          this.createResultsDisplay.step4c = `\u2705 Skipped adding canonical domain snippets; no canonical redirects requested.`
        }

        // step 5: create documentation snippet
        const step5aresp = await ServicesApi.createPantheonBackendsDict({serviceId: createdServiceId})
        this.createResults.step5a = step5aresp
        if (step5aresp.success) {
          this.createResultsDisplay.step5a = `\u2705 Created pantheon_backends dictionary.`
        } else {
          // leave this as-is for now; not the end of the world that this failed.
        }

        // step 5: create documentation snippet
        const step5bresp = await ServicesApi.createStandardSnippets({
          serviceId: createdServiceId,
          client_name: payload.documentation.client_name,
          org_uuid: payload.documentation.org_uuid,
          site_uuids: payload.documentation.site_uuids,
          addl_data: payload.documentation.addl_data,
          useIo: this.useIo,
        })
        this.createResults.step5b = step5bresp
        if (step5bresp.success) {
          this.createResultsDisplay.step5b = `\u2705 Added standard snippets.`
        } else {
          // leave this as-is for now; not the end of the world that this failed.
        }

        if (this.useWaf) {
          // Step 8a - add WAF allowlist ACL
          const step8aresp = await ServicesApi.createWafAcl({ serviceId: createdServiceId })
          this.createResults.step8a = step8aresp
          if (step8aresp.success) {
            this.createResultsDisplay.step8a = `\u2705 Created WAF ACL.`

            // step 8b - populate WAF allowlist ACL
            const step8bresp = await ServicesApi.populateWafAcl({
              serviceId: createdServiceId,
              aclId: step8aresp.data.id
            })
            this.createResults.step8b = step8bresp;
            if (step8bresp.success) {
              this.createResultsDisplay.step8b = '\u2705 Populated WAF ACL.'
            } else {
              // leave this as-is for now
            }

            // step 8c - create the waf allowlist snippet
            // This can happen if the acl was created, regardless of whether or not the acl was populated
            const step8cresp = await ServicesApi.createWafAllowlistSnippet({ serviceId: createdServiceId })
            this.createResults.step8c = step8cresp
            if (step8cresp.success) {
              this.createResultsDisplay.step8c = '\u2705 Created WAF allowlist snippet.'
            } else {
              // leave this for now
            }

            // step 8d - create the waf domain if there's an org or site uuid.
            // if there are 1 or more site uuids, create a site-uuid domain for each
            // else, if there is an org uuid, create an org-uuid domain
            if (this.step8dAvailable) {
              if (this.orgUuid != '') {
                const singleOrgDomainResponse = await ServicesApi.addDomain({
                  serviceId: createdServiceId,
                  domainName: `org-${this.orgUuid}.waf.ps-pantheon.com`
                })
                this.createResults.step8d = singleOrgDomainResponse
                if (singleOrgDomainResponse.success) {
                  this.createResultsDisplay.step8d = '\u2705 Created 1 WAF domains based on org UUID.'
                } else {
                  this.createResultsDisplay.step8d = '\u274c Unable to create WAF domain.'
                }
              }
              else if (this.siteUuids.filter(d => d && d !== '').length >= 1) {
                // create site domains for each one found
                const domainList = this.siteUuids.map(uuid => {
                  return ServicesApi.addDomain({
                    serviceId: createdServiceId,
                    domainName: `site-${uuid}.waf.ps-pantheon.com`
                  })
                })
                const multiSiteDomainsResponse = await Promise.all(domainList)
                this.createResults.step8d = multiSiteDomainsResponse
                if (multiSiteDomainsResponse.map(p => p.success).every(s => s)) {
                  this.createResultsDisplay.step8d = '\u2705 Created 1 or more WAF domains based on site UUIDs.'
                } else {
                  this.createResultsDisplay.step8d = '\u26a0 Some WAF domains could not be created. Check the service for details.'
                }
              }
              else {
                this.createResultsDisplay.step8d = '\u274c Unable to create WAF domains - no UUIDs found.'
              }
            }
          } else {
            this.createResultsDisplay.step8a = '\u274c Unable to create WAF ACL.'
            this.createResultsDisplay.step8b = '\u274c Skipped adding WAF ACL items.'
            this.createResultsDisplay.step8c = '\u274c Skipped adding WAF allowlist snippet.'
          }
        } else {
          this.createResultsDisplay.step8a = '\u2705 Skipped creating WAF ACL by user request.'
          this.createResultsDisplay.step8b = '\u2705 Skipped populating WAF ACL by user request.'
          this.createResultsDisplay.step8c = '\u2705 Skipped populating WAF allowlist snippet by user request.'
        }

        if (this.useIpBA) {
          // step 9a - create ip block/allowlist acl
          const step9aresp = await ServicesApi.createIpBAAcl({
            serviceId: createdServiceId,
            type: this.ipBAType,
          })
          this.createResults.step9a = step9aresp
          if (step9aresp.success) {
            this.createResultsDisplay.step9a = `\u2705 Created IP ${this.ipBAType}list.`
          } else {
            this.createResultsDisplay.step9a = `\u274C Unable to create IP ${this.ipBAType}list.`
          }

          // step 9b - upload blocklist or allowlist snippet
          const step9bresp = await ServicesApi.createIpBASnippets({
            serviceId: createdServiceId,
            type: this.ipBAType
          })
          this.createResults.step9b = step9bresp
          if (step9bresp.success) {
            this.createResultsDisplay.step9b = `\u2705 Created IP ${this.ipBAType}list snippet.`
          } else {
            this.createResultsDisplay.step9b = `\u274c Unable to create IP ${this.ipBAType}list snippet.`
          }
        } else {
          this.createResultsDisplay.step9a = `\u2705 Skipped creating IP block/allowlist by user request.`
          this.createResultsDisplay.step9b = `\u2705 Skipped creating IP block/allowlist snippets by user request.`
        }

        if (this.useGeoBA) {
          //step 9c - create geo block/allowlist dictionary
          const step9cresp = await ServicesApi.createGeoBADict({
            serviceId: createdServiceId,
            type: this.geoBAType
          })
          this.createResults.step9c = step9cresp
          if (step9cresp.success) {
            this.createResultsDisplay.step9c = `\u2705 Created Geo ${this.geoBAType}list.`
          } else {
            this.createResultsDisplay.step9c = `\u274c Unable to create Geo ${this.geoBAType}list.`
          }

          // step 9d = upload geo blocklist or allow snippet
          const step9dresp = await ServicesApi.createGeoBASnippets({
            serviceId: createdServiceId,
            type: this.geoBAType
          })
          this.createResults.step9d = step9dresp
          if (step9dresp.success) {
            this.createResultsDisplay.step9d = `\u2705 Created Geo ${this.geoBAType}list snippet.`
          } else {
            this.createResultsDisplay.step9d = `\u274c Unable to create Geo ${this.geoBAType}list snippet.`
          }
        } else {
          this.createResultsDisplay.step9c = `\u2705 Skipped created Geo block/allowlist by user request.`
          this.createResultsDisplay.step9d = `\u2705 Skipped created Geo block/allowlist snippets by user request.`
        }

        // step 9e - create blocked traffic logging snippet
        if (this.useIpBA || this.useGeoBA) {
          const step9eresp = await ServicesApi.setIpGeoBlockLogging({
            serviceId: createdServiceId,
            ip: this.useIpBA,
            ip_type: this.ipBAType,
            geo: this.useGeoBA,
            geo_type: this.geoBAType,
          })
          this.createResults.step9e = step9eresp
          if (step9eresp.success) {
            this.createResultsDisplay.step9e = `\u2705 Created blocked traffic logging snippet.`
          } else {
            this.createResultsDisplay.step9e = `\u274c Unable to create blocked traffic logging snippet.`
          }
        } else {
          this.createResultsDisplay.step9e = `\u2705 Skipped creating blocked traffic logging snippet by user request.`
        }

        // Step 6a - add additional domains to the fastly service
        const filteredAdditionalDomains = payload.additionalDomains.filter(d => d !== '')
        const domainPromises = filteredAdditionalDomains
          // create a list of callbacks for the remaining domains
          .map(d => ServicesApi.addDomain({
            serviceId: createdServiceId,
            domainName: d
          }))
        const step6aResp = await Promise.all(domainPromises)
        if (step6aResp.map(p => p.success).every(s => s)) {
          this.createResultsDisplay.step6a = '\u2705 Added custom domains.'
        } else {
          // maybe 1 failed, maybe they all did.
        }
        // Step 6b - create conditions for each of the domains
        const conditionPromises = filteredAdditionalDomains
          // create a list of callbacks for the remaining domains
          .map(d => ServicesApi.addDomainCondition({
            serviceId: createdServiceId,
            domainName: d
          }))
        const step6bResp = await Promise.all(conditionPromises)
        if (step6bResp.map(p => p.success).every(s => s)) {
          this.createResultsDisplay.step6b = '\u2705 Added custom domain conditions.'
        } else {
          // maybe 1 failed, maybe they all did.
        }

        // Step 11a - activate service
        if (this.additionalDomains.filter(d => d !== '').length > 0) {
          const step11aResp = await ServicesApi.activateService({ serviceId: createdServiceId })
          this.createResults.step11a = step11aResp
          if (step11aResp.success) {
            this.createResultsDisplay.step11a = `\u2705 Activated version 1 of service ${createdServiceId}.`
          } else {
            this.createResultsDisplay.step11a = `\u274c Unable to activate new service.`
          }

          // Step 11b - start TLS provisioning
          const tlsCallbacks = this.additionalDomains.filter(d => d !== '').map(domain => ServicesApi.provisionTls({ domain }))
          const step11bResp = await Promise.all(tlsCallbacks)
          this.createResults.step11b = step11bResp
          if (step11bResp.map(p => p.success).every(s => s)) {
            const tlsJson = step11bResp.map(sub => {
              const [cnameChallenge] = sub.data.challenges.filter(c => c.record_type === 'CNAME')
              return {
                domain: cnameChallenge.record_name.replace('_acme-challenge.', ''),
                challenge_type: cnameChallenge.record_type,
                record_name: cnameChallenge.record_name,
                record_value: cnameChallenge.values[0],
              }
            })
            const headers = Object.keys(tlsJson[0])
            const tlsCsv = [
              headers.map(k => `"${k}"`).join(',')
            ]
            tlsJson.forEach(j => {
              // We need to ensure that the values are all in the same order.
              let outString = ""
              for(let i = 0; i < headers.length; i++) {
                outString += `"${j[headers[i]]}",`
              }
              outString = outString.replace(/,$/, '')
              tlsCsv.push(outString)
            })
            this.tlsCsvDownloadUrl = window.URL.createObjectURL(
              new Blob([tlsCsv.join('\r\n')], { type: 'text/csv' })
            )

            this.createResultsDisplay.step11b = `\u2705 Started TLS subscriptions for all domains. Download <a href="${this.tlsCsvDownloadUrl}" download="${createdServiceId}_tls.csv">here</a>.`
          } else {
            this.createResultsDisplay.step11b = '\u274c Unable to start one or more TLS subscriptions.'
          }
        } else {
          this.createResultsDisplay.step11a = '\u2705 Skipped service activation (no domains).'
          this.createResultsDisplay.step11b = '\u2705 Skipped TLS subscriptions (no domains).'
        }

      } catch (e) {
        throw e
      } finally {
        this.loading = false
      }

      try {
        this.loading = true
        // Step 2: update the firebase database to include the new service
        const toPatch = {
          client_name: this.serviceName,
          serviceId: createdServiceId,
          siteUUID: this.siteUuids,
          orgUUID: this.orgUuid,
          prefix: '',
          hosts: [
            ...this.additionalDomains.filter(d => d !== ''),
            ...this.siteUuids.filter(d => d !== '').map(uuid => `site-${uuid}.waf.ps-pantheon.com`),
            this.orgUuid != '' ? `org-${this.orgUuid}.waf.ps-pantheon.com` : ''
          ].filter(d => d != ''),
          docSnippetName: 'documentation',
          backends: [],
          hasBlockLog: (this.useIpBA || this.useGeoBA),
          hasIo: this.useIo,
          apiKey: this.serviceName.replace(/[\W-]/g, '_').toLowerCase(),
        }
        if (toPatch.hasBlockLog) {
          if (this.useIpBA) {
            toPatch.blockLogDefaultGroup = 'ip';
          } else {
            toPatch.blockLogDefaultGroup = 'geo_country';
          }
        }
        const fbResponse = await FirebaseApi.addClient(toPatch)

        this.createResults.step6 = fbResponse
        this.createResultsDisplay.step7 = `\u2705 Added Fastly service to Firebase with client key "${toPatch.apiKey}".`
      } catch (e) {
        throw 'Error adding service to Firebase: ' + e.toString()
      } finally {
        this.loading = false
        // eslint-disable-next-line no-console
        console.log(this.createResults)
      }
    },
  },
  computed: {
    useWaf: function() {
      return this.wafIoConfig == 'waf' || this.wafIoConfig == 'waf-io';
    },
    useIo: function() {
      return this.wafIoConfig == 'io' || this.wafIoConfig == 'waf-io';
    },
    showHide: function() {
      if (this.loading) {
        return 'show'
      } else {
        return 'hide'
      }
    },
    step8dAvailable: function() {
      if (this.orgUuid) {
        return true;
      }
      if (this.siteUuids.length > 1) {
        return true;
      }
      if (this.siteUuids[0] != null && this.siteUuids[0] != '') {
        return true;
      }
      return false;
    },
    serviceNameFormatted: function() {
      return this.serviceName
        .toLowerCase()
        .replace(/\s/g, '-');
    }
  },
}
</script>
