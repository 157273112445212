/* eslint-disable no-unused-vars */
import axios from 'axios'
import { getInstance } from '@pantheon-systems/auth0-vue-module'
import VueCookies from 'vue-cookies'

// Since this is a V2 api, use a clean version of Axios
const axiosV2 = axios.create()
const { VUE_APP_MICROSERVICE_V2_URL } = process.env
// In development environments, "/_apiv2" will be part of the env var.
// In production environments, it won't be there.
axiosV2.defaults.baseURL = VUE_APP_MICROSERVICE_V2_URL

/** The token to use. Stash it here the first time it's used. */
let token_class = null

const dispatch = async({
  method = 'patch',
  url = '/',
  payload = null,
  _token = null
}) => {
  if (! _token) {
    if (! token_class) {
      const newToken = await getInstance().getTokenSilently()
      token_class = newToken
    }
  }
  let options = {
    url,
    method,
    json: true,
    headers: {
      Accept: 'application/vnd.api+json',
      "X-Adv-Cdn-Token": 'testing-key',
      Authorization: `Bearer ${token_class}`
    },
    token: token_class
  }

  if (VueCookies.isKey('fastly-api-key')) {
    options.headers = {...options.headers, ...{'Fastly-Key': VueCookies.get('fastly-api-key')}};
  }

  if (payload) {
    options.data = payload
  }

  try {
    const resp = await axiosV2.request(options)
    return {
      error: false,
      data: resp || null
    }
  } catch (e) {
    return {
      error: true,
      data: e
    }
  }
}

/**
 * Generic routine to create ACLs
 * @param {String} serviceId The service ID to use
 * @param {String} name The name of the ACL to create
 */
const createAcl = async (serviceId, name) => {
  const url = `/service/${serviceId}/version/1/createAcl/${name}`
  const ret = {}
  try {
    const resp = await dispatch({ url })
    if (resp.error) {
      ret.error = true;
      ret.data = { message: `unable to create ACL with name ${name} on service ${serviceId}.`}
    } else {
      ret.error = false;
      ret.data = {
        message: 'success',
        name: resp.data.data.name,
        serviceId,
        aclId: resp.data.data.id,
      }
    }
    return ret
  } catch (e) {
    ret.error = true;
    ret.data = { message: 'error while calling API' }
    return ret;
  }
}

/**
 *
 * @param {String} serviceId The service ID to use
 * @param {String} name The name of the dictionary to create
 */
const createDict = async(serviceId, name) => {
  const url = `/service/${serviceId}/version/1/createDictionary/${name}`
  const ret = {}
  try {
    const resp = await dispatch({ url })
    if (resp.error) {
      ret.error = true;
      ret.data = { message: `unable to create Dictionary with name ${name} on service ${serviceId}.` }
    } else {
      ret.error = false;
      ret.data = {
        message: 'success',
        name: resp.data.data.name,
        serviceId,
        dictId: resp.data.data.id,
      }
    }
    return ret;
  } catch (e) {
    ret.error = true
    ret.data = { message: 'error while calling API' }
    return ret
  }
}

/**
 * Get the Fastly IP range in a bulk-update-compatible format
 */
const wafAllowlistData = async () => {
  const url = '/misc/fastly-ip-list'
  const fallback = { entries: [] }
  try {
    const resp = await dispatch({ url, method: 'get' })
    if (resp.error === false) {
      const returned = resp.data.data
      const entries = [
        ...returned.addresses,
        ...returned.ipv6_addresses
      ].map(ip => {
        const subnet = ip.match(/^([\da-f.:]+)\/(\d+)$/)
        if (subnet) {
          return {
            op: 'create',
            ip: subnet[1],
            subnet: subnet[2]
          }
        } else {
          return { op: 'create', ip }
        }
      })
      return { entries }
    } else {
      return fallback
    }
  } catch (e) {
    return fallback
  }
}

export default {

  /**
   *
   * @param {Object} payload
   * @param {String} payload.name The name of the service to create
   */
  async addService({
    name
  }) {
    const url = '/services/add'
    try {
      const resp = await dispatch({
        url,
        payload: { name }
      })
      // eslint-disable-next-line no-console
      const ret = {
        success: !resp.error,
        description: 'Create Fastly service'
      }
      if (ret.success) {
        ret.data = {
          serviceId: resp.data.data.id,
          serviceName: resp.data.data.name
        }
      } else {
        ret.data = {
          error: 'tbd'
        }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  async addBlackholeCondition({
    serviceId = '',
  }) {
    return dispatch({
      url: `/service/${serviceId}/version/1/condition`,
      payload: {
        serviceId,
        version: 1,
        name: 'blackhole',
        comment: '',
        type: 'REQUEST',
        statement: 'false'
      }
    })
  },
  /**
   * Add a Pantheon origin
   */
  async addOrigin({
    serviceId = '',
    pop = '',
  }) {
    const url = `/service/${serviceId}/version/1/shield-backend-${pop}`;
    try {
      const resp = await dispatch({ url })
      const ret = {
        success: !resp.error,
        description: 'Add Pantheon origin'
      }
      if (ret.success) {
        ret.data = resp.data
      } else {
        ret.data = {
          error: 'tbd'
        }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  /**
   * Add the boilerplate VCL
   *
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID to update
   * @param {Boolean} [payload.setMain=true] Whether or not to set the VCL as the default
   * @param {Boolean} [payload.useWaf=false] Whether or not to use the WAF boilerplate instead of the normal boilerplate
   */
  async addBoilerplate({
    serviceId = '',
    setMain = true,
    useWaf = false,
    useIo = false,
  }) {
    const url = `/service/${serviceId}/version/1/customVcls`
    let boilerplateType = 'standard';
    let snippetName = 'GCDN';
    if (useWaf && useIo) {
      boilerplateType = 'waf_io';
      snippetName = `${snippetName} + WAF + IO`;
    } else {
      if (useWaf) {
        boilerplateType = 'waf';
        snippetName = `${snippetName} + WAF`;
      }
      if (useIo) {
        boilerplateType = 'io';
        snippetName = `${snippetName} + IO`;
      }
    }
    try {
      const resp = await dispatch({
        url,
        payload: {
          useBoilerplate: true,
          useWafBoilerplate: useWaf,
          boilerplateType,
          setMain,
          data: {
            name: snippetName,
          }
        }
      })
      const ret = {
        success: !resp.error,
        description: 'Add custom boilerplate VCL'
      }
      if (ret.success) {
        ret.data = {
          name: resp.data.data.name
        }
      } else {
        ret.data = {
          error: 'tbd'
        }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  /**
   * Create the logging object
   *
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID to update
   * @param {String} [payload.name=ACDN Logging] The name of the log snippet
   * @param {String} [payload.project_id=pantheon-psapps] The project name
   * @param {String} [payload.dataset=client_logs] The dataset
   * @param {String} [payload.table=client_logs] The table
   */
  async addLogging({
    serviceId = '',
    name = 'AGCDN Logging',
    project_id = 'pantheon-psapps',
    dataset = 'client_logs',
    table = 'client_logs',
  }) {
    const url = '/logging/bq/add'
    try {
      const resp = await dispatch({
        url,
        payload: {
          serviceId,
          version: 1,
          useBoilerplate: true,
          data: {
            name,
            project_id,
            dataset,
            table,
          }
        }
      })
      const ret = {
        success: !resp.error,
        description: 'Add logging to GCS'
      }
      if (ret.success) {
        ret.data = { name: resp.data.data.name }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  /**
   * Create the IO logging objects
   *
   * @param {Object} payload
   * @param {String} payload.serviceID The service ID to update
   */
  async setIoLogging({
    serviceId = '',
  }) {
    const ret = {
      description: 'Set up blocked traffic logging',
      data: {}
    };

    try {

      const conditionResp = await dispatch({
        url: `/service/${serviceId}/version/1/condition`,
        payload: {
          serviceId,
          version: 1,
          name: 'IO logging',
          comment: '',
          type: 'RESPONSE',
          statement: 'req.http.x-fastly-imageopto-api ~ "^fastly"'
        }
      })
      ret.data.conditionResp = conditionResp;

      const loggingResp = await dispatch({
        url: '/logging/bq/add',
        payload: {
          serviceId,
          version: 1,
          useImageOpto: true,
          data: {
            name: 'IO logging',
            project_id: 'pantheon-psapps',
            dataset: 'io_stats',
            table: 'io_stats',
            response_condition: 'IO logging',
          }
        }
      })
      ret.data.loggingResp = loggingResp;

      ret.success = [conditionResp, loggingResp].every(r => !r.error)

      if (!ret.success) {
        ret.data.error = { error: 'tbd' };
      }

      return ret
    } catch (e) {
      return {
        success: false,
        error: e
      }
    }
  },

  /**
   * Create  the canonical dictionary
   *
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID in which to create the dictionary
   */
  async createCanonicalDict({
    serviceId = ''
  }) {
    const url = `/service/${serviceId}/version/1/createDictionary/canonical`
    try {
      const resp = await dispatch({
        url
      })
      const ret = {
        success: !resp.error,
        description: 'Create canonical redirect dictionary'
      }
      if (ret.success) {
        ret.data = {
          id: resp.data.data.id,
          name: resp.data.data.name
        }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  /**
   * Create  the pantheon_backends dictionary
   *
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID in which to create the dictionary
   */
   async createPantheonBackendsDict({
    serviceId = ''
  }) {
    const url = `/service/${serviceId}/version/1/createDictionary/pantheon_backends`
    try {
      const resp = await dispatch({
        url
      })
      const ret = {
        success: !resp.error,
        description: 'Create pantheon_backends dictionary'
      }
      if (ret.success) {
        ret.data = {
          id: resp.data.data.id,
          name: resp.data.data.name
        }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  /**
   * Create canonical dictionary entries
   *
   * @param {Object} payload
   * @param {String} payload.serviceId The service id to use
   * @param {String} payload.dictId The dictionary ID
   * @param {Array<Object>} payload.entries The list of canonical entries
   */
  async createCanonicalEntries({
    serviceId = '',
    dictId = '',
    entries = []
  }) {
    const url = `/service/${serviceId}/version/1/dictionary/${dictId}`
    const items = {
      items: entries.map(i => ({
        op: 'create',
        item_key: i.from,
        item_value: i.to
      }))
    }
    try {
      const resp = await dispatch({
        url,
        payload: items
      })
      const ret = {
        description: 'Create canonical redirect dictionary entries',
        success: !resp.error
      }
      if (ret.success) {
        ret.data = resp.data
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        error: e
      }
    }
  },

  /**
   * Create Block/Allowlist ACL
   *
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID in which to create the ACL
   * @param {String} [payload.type=blocklist] The type of acl to create (cosmetic only)
   */
  async createIpBAAcl({
    serviceId = '',
    type = 'blocklist',
  }) {
    try {
      const aclResult = await createAcl(serviceId, `ip_${type}`)
      const ret = {
        success: !aclResult.error,
        description: `Create IP ${type} ACL`
      }
      if (! aclResult.error) {
        ret.data = {
          id: aclResult.data.aclId,
          name: aclResult.data.name
        }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret;
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  /**
   * Create Block/Allowlist Dict
   *
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID in which to create the Dictionary
   * @param {String} [payload.type=blocklist] The type of acl to create (cosmetic only)
   */
  async createGeoBADict({
    serviceId = '',
    type = 'blocklist',
  }) {
    try {
      const dictResult = await createDict(serviceId, `geo_${type}`)
      const ret = {
        success: !dictResult.error,
        description: `Create Geo ${type} Dictionary`
      }
      if (! dictResult.error) {
        ret.data = {
          id: dictResult.data.dictId,
          name: dictResult.data.name
        }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  /**
   * Create the WAF allowlist ACL
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID to use
   */
  async createWafAcl({
    serviceId = ''
  }) {
    try {
      const aclResult = await createAcl(serviceId, 'waf_allowlist')
      const ret = {
        success: !aclResult.error,
        description: 'Create WAF allowlist ACL'
      }
      if (! aclResult.error) {
        ret.data = {
          id: aclResult.data.aclId,
          name: aclResult.data.name
        }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret;
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  /**
   * Populate the WAF ACL with Fastly's IP range
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID
   * @param {String} payload.aclId The ACL ID
   */
  async populateWafAcl({
    serviceId = '',
    aclId = '',
  }) {
    const bulkData = await wafAllowlistData();
    const url = `/service/${serviceId}/version/1/acl/${aclId}`
    try {
      const resp = await dispatch({
        url,
        payload: bulkData,
      })
      const ret = {
        description: 'Create WAF Allowlist entries',
        success: !resp.error
      }
      if (ret.success) {
        ret.data = resp.data
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret;
    } catch (e) {
      return {
        success: false,
        error: e
      }
    }
  },

  /**
   * Create the WAF allowlist snippet
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID to use
   */
  async createWafAllowlistSnippet({
    serviceId = ''
  }) {
    const url = `/service/${serviceId}/version/1/vcls`
    try {
      const resp = await dispatch({
        url,
        payload: {
          useWafAllowlist: true,
          data: {
            type: 'recv',
            priority: 0,
            dynamic: 0,
            name: 'waf allowlist'
          }
        }
      })
      const ret = {
        description: 'Create WAF allowlist snippet',
        success: !resp.error
      }
      if (ret.success) {
        ret.data = resp.data
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret;
    } catch (e) {
      return {
        success: false,
        error: e
      }
    }
  },

  /**
   * Create an IP blocklist or allowlist snippet
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID to use
   * @param {String} [payload.type=blocklist] The type of snippet to create
   */
  async createIpBASnippets({
    serviceId = '',
    type = 'blocklist'
  }) {
    const url = `/service/${serviceId}/version/1/vcls`
    const toDispatch = {
      url,
      payload: {
        data: {
          type: 'recv',
          priority: 1,
          dynamic: 0
        }
      }
    }
    if (type === 'blocklist') {
      toDispatch.payload.useIpBlocklist = true;
      toDispatch.payload.data.name = 'ip blocklist'
    } else {
      toDispatch.payload.useIpAllowlist = true;
      toDispatch.payload.data.name = 'ip allowlist'
    }
    try {
      const resp = await (dispatch(toDispatch))
      const ret = {
        description: 'Create IP block/allowlist snippets',
        success: !resp.error
      }
      if (ret.success) {
        ret.data = resp.data
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        error: e
      }
    }
  },

  /**
   * Create a Geo blocklist or allowlist snippet
   * @param {Object} payload
   * @param {String} payload.serviceId The service ID to use
   * @param {String} [payload.type=blocklist] The type of snippet to create
   */
  async createGeoBASnippets({
    serviceId = '',
    type = 'blocklist'
  }) {
    const url = `/service/${serviceId}/version/1/vcls`
    const toDispatch = {
      url,
      payload: {
        data: {
          type: 'recv',
          priority: 1,
          dynamic: 0
        }
      }
    }
    if (type === 'blocklist') {
      toDispatch.payload.useGeoBlocklist = true;
      toDispatch.payload.data.name = 'geo blocklist'
    } else {
      toDispatch.payload.useGeoAllowlist = true;
      toDispatch.payload.data.name = 'geo allowlist'
    }
    try {
      const resp = await (dispatch(toDispatch))
      const ret = {
        description: 'Create Geo block/allowlist snippets',
        success: !resp.error
      }
      if (ret.success) {
        ret.data = resp.data
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        error: e
      }
    }
  },

  /**
   * Create canonical redirect snippets
   *
   * @param {Object} payload
   * @param {String} payload.serviceId The service id to use
   */
  async createCanonicalSnippets({
    serviceId = ''
  }) {
    const url = `/service/${serviceId}/version/1/vcls`
    const createSnippets = [
      dispatch({
        url,
        payload: {
          useBoilerplateRecv: true,
          data: {
            name: 'canonical recv',
            type: 'recv',
            priority: 5,
            dynamic: 0,
          }
        }
      }),
      dispatch({
        url,
        payload: {
          useBoilerplateErr: true,
          data: {
            name: 'canonical error',
            type: 'error',
            dynamic: 0
          }
        }
      })
    ]
    try {
      const resp = await Promise.all(createSnippets)
      const ret = {
        description: 'Create canonical redirect snippets',
        success: resp.every(r => r.error === false)
      }
      if (ret.success) {
        ret.data = resp.map(r => ({ id: r.data.data.id, name: r.data.data.name }))
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  async setIpGeoBlockLogging({
    serviceId = '',
    ip = false,
    ip_type = 'blocklist',
    geo = false,
    geo_type = 'blocklist',
  }) {
    const payload = {
      setBlockLog: true,
      logIp: false,
      logGeo: false,
      data: {
        name: 'geoblock logging',
        type: 'log',
        dynamic: 0
      }
    }
    if (ip && geo) {
      payload.logIp = true
      payload.logGeo = true
      payload.ipLogType = ip_type.replace(/list$/, '')
      payload.geoLogType = geo_type.replace(/list$/, '')
    } else if (ip) {
      payload.logIp = true
      payload.ipLogType = ip_type.replace(/list$/, '')
    } else if (geo) {
      payload.logGeo = true
      payload.geoLogType = geo_type.replace(/list$/, '')
    } else {
      payload.setBlockLog = false
    }

    const ret = {
      description: 'Set up blocked traffic logging',
      data: {}
    };

    try {
      const vclResp = await dispatch({
        url: `/service/${serviceId}/version/1/vcls`,
        payload,
      })
      ret.data.vclResp = vclResp;

      const conditionResp = await dispatch({
        url: `/service/${serviceId}/version/1/condition`,
        payload: {
          serviceId,
          version: 1,
          name: 'geoblock logging',
          comment: '',
          type: 'RESPONSE',
          statement: 'var.geo_blocked'
        }
      })
      ret.data.conditionResp = conditionResp;

      const loggingResp = await dispatch({
        url: '/logging/bq/add',
        payload: {
          serviceId,
          version: 1,
          useBlockedTraffic: true,
          data: {
            name: 'geoblock logging',
            project_id: 'pantheon-psapps',
            dataset: 'misc_logs',
            table: 'geo_block',
            response_condition: 'geoblock logging',
          }
        }
      })
      ret.data.loggingResp = loggingResp;

      ret.success = [vclResp, conditionResp, loggingResp].every(r => !r.error)

      if (!ret.success) {
        ret.data.error = { error: 'tbd' };
      }

      return ret
    } catch (e) {
      return {
        success: false,
        error: e
      }
    }
  },

  async createStandardSnippets({
    serviceId = '',
    client_name = '',
    org_uuid = '',
    site_uuids = [],
    addl_data = {},
    useIo = false,
  }) {
    const ret = {
      description: 'Set up blocked traffic logging',
      data: {}
    };

    const responses = [];

    const url = `/service/${serviceId}/version/1/vcls`
    try {
      const docResp = await dispatch({
        url,
        payload: {
          useBoilerplateDoc: true,
          client_name,
          org_uuid,
          site_uuids,
          data: {
            name: 'documentation',
            type: 'init',
            dynamic: 0
          },
          addl_data,
        }
      })
      ret.data.docResp = docResp;
      responses.push(docResp);

      const disableIOShieldResp = await dispatch({
        url,
        payload: {
          disableIOShield: true,
          data: {
            name: 'disable IO RECV',
            priority: 170,
            type: 'recv',
            dynamic: 0
          }
        }
      })
      ret.data.disableIOShieldResp = disableIOShieldResp;
      responses.push(disableIOShieldResp);

      if (useIo) {
        const ioShardResp = await dispatch({
          url,
          payload: {
            ioShard: true,
            data: {
              name: 'IO shard RECV',
              priority: 160,
              type: 'recv',
              dynamic: 0
            }
          }
        })
        ret.data.ioShardResp = ioShardResp;
        responses.push(ioShardResp);
      }
      
      const pantheonBackendResp = await dispatch({
        url,
        payload: {
          pantheonBackend: true,
          data: {
            name: 'pantheon backend RECV',
            priority: 150,
            type: 'recv',
            dynamic: 0
          }
        }
      })
      ret.data.pantheonBackendResp = pantheonBackendResp;
      responses.push(pantheonBackendResp);

      ret.success = responses.every(r => !r.error)

      if (!ret.success) {
        ret.data.error = { error: 'tbd' };
      }

      return ret
    } catch (e) {
      return {
        success: false,
        data: {
          error: e
        }
      }
    }
  },

  async addDomain({
    serviceId = '',
    domainName = '',
  }) {
    const url = `/service/${serviceId}/version/1/domain/${domainName}`
    try {
      const resp = await dispatch({ url })
      const ret = {
        description: `Add domain ${domainName}`,
        success: !resp.error
      }
      if (ret.success) {
        ret.data = {
          name: resp.data.data.name
        }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret;
    } catch (e) {
      return {
        success: false,
        data: { error: e }
      }
    }
  },

  async addDomainCondition({
    serviceId = '',
    domainName = '',
  }) {
    const url = `/service/${serviceId}/version/1/condition`
    try {
      const resp = await dispatch({
        url,
        payload: {
          name: domainName,
          comment: domainName,
          type: 'REQUEST',
          statement: `req.http.host ~ "(?i)^${domainName.replace(/\./g, String.fromCharCode(92, 46))}$"`
        }
      })
      const ret = {
        description: `Add condition for domain ${domainName}`,
        success: !resp.error
      }
      if (ret.success) {
        ret.data = {
          name: resp.data.data.name
        }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret;
    } catch (e) {
      return {
        success: false,
        data: { error: e }
      }
    }
  },

  async activateService({
    serviceId = '',
  }) {
    const url = `/service/${serviceId}/version/1/activate`
    try {
      const resp = await dispatch({method: 'get', url })
      const ret = {
        description: `Activate service`,
        success: !resp.error
      }
      if (ret.success) {
        ret.data = {
          version: resp.data.number
        }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret;
    } catch (e) {
      return {
        success: false,
        data: { error: e }
      }
    }
  },

  async provisionTls({
    domain = ''
  }) {
    const url = `/tls/subscription`
    try {
      const resp = await dispatch({
        url,
        payload: { domain }
      })
      const ret = {
        description: `TLS subscription - ${domain}`,
        success: !resp.error
      }
      if (ret.success) {
        ret.data = {
          challenges: resp.data.data.included[0].attributes.challenges
        }
      } else {
        ret.data = { error: 'tbd' }
      }
      return ret
    } catch (e) {
      return {
        success: false,
        data: { error: e }
      }
    }
  },
}
