<template>
  <div class="collapsible-form-group">
    <b-form-group>
      <div class="d-flex justify-content-between align-items-baseline">
        <label v-bind:for="collapseName">
          <h3>
            {{ title }}
            <br v-if="subHeading">
            <small v-if="subHeading" class="text-muted">{{ subHeading }}</small>
          </h3>
        </label>
        <b-button variant="primary" v-b-toggle="collapseName">{{ showHideText }}</b-button>
      </div>
    </b-form-group>

    <b-collapse :id="collapseName" visible>
      <b-card class="mb-2">
        <slot></slot>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'collapsible-form-group',
  props: {
    collapseName: String,
    title: String,
    subHeading: String,
  },
  data() {
    return {
      showHideText: 'Hide',
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == this.collapseName) {
        this.showHideText = isJustShown
          ? 'Hide'
          : 'Show'
      }
    })
  }
}
</script>
