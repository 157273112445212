import axios from 'axios'
import { getInstance } from '@pantheon-systems/auth0-vue-module'

// Since this is a V2 api, use a clean version of Axios
const axiosV2 = axios.create()
const { VUE_APP_MICROSERVICE_V2_URL } = process.env
// In development environments, "/_apiv2" will be part of the env var.
// In production environments, it won't be there.
axiosV2.defaults.baseURL = VUE_APP_MICROSERVICE_V2_URL

export default {
  async addClient(payload) {
    const token = await getInstance().getTokenSilently()

    try {
      const resp = await axiosV2.post('/client/add', {
        ...payload
      }, {
        headers: {
          'X-Adv-Cdn-Token': 'testing-key',
          Authorization: `Bearer ${token}`
        }
      })

      return resp
    } catch (e) {
      if (e.response) {
        return e.response.data
      } else if (e.request) {
        return e.request
      } else {
        return e.message
      }
    }
  }
}
